import React from "react"
import { Footer, SEO, Layout, Hero, Container } from "@components"
import { Flex } from "rebass"
import { Card } from "../components/pages/single-table-models-library/card"
import FAQ from "../components/pages/single-table-models-library/FAQ"

const SingleTableModelsLibrary = props => {
  const { models } = props.pageContext

  return (
    <>
      <Layout>
        <SEO
          title="DynamoDB Single-Table Design Examples [Interactive Library]"
          description="View pre-made DynamoDB single table design models made by experts - use them as reference for your own DynamoDB one-table models and access patterns."
        />
        <Hero
          title={"DynamoDB Single-Table Design Examples Library"}
          subtitle="Collection of reference DynamoDB single-table models helping you design your models and access patterns"
        />
        <div>
          <Container maxWidth={1000}>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ paddingTop: "20px" }}
            >
              {(models || []).map(model => (
                <Card
                  model={model.model}
                  icon={model.icon}
                  metadata={model.readme.data}
                  content={model.readme.content}
                />
              ))}
            </Flex>
            <FAQ />
          </Container>
          <div style={{ marginBottom: "100px" }}></div>
          <Footer noMarginTop />
        </div>
      </Layout>
    </>
  )
}

export default SingleTableModelsLibrary
