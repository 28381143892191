import React from "react"
import { Box } from "rebass"

const FAQ = () => (
  <Box mt={62}>
    <h2>Frequently Asked Questions</h2>
    <h3>What is this?</h3>
    <p>
      "Single-Table Examples Library" is a curated directory of approved DynamoDB
      designs which might help you understand modeling by analyzing examples.
      They can also serve you as a reference point when trying to implement your
      own models.
    </p>
    <h3>Can I add my own models here?</h3>
    <p>
      Yes! If you believe your model could be helpful to the community, you can
      add it to this directory by submitting a pull request{" "}
      <a href="https://github.com/Dynobase/single-table-models">
        to this repo.
      </a>{" "}
      Please respect contributing rules. After merging pull request, it will
      appear here after some time.
    </p>
    <h3>How can I create models like these?</h3>
    <p>
      You can use {" "}
      <a href="https://dynobase.dev/dynamodb-single-table-design-tool/">
        Dynobase's
      </a>{" "}
      Single-table designer to create your own. It is a simple, Excel-like tool
      that can help you create models in a few clicks.
    </p>
  </Box>
)

export default FAQ
