import React from "react"
import { Flex, Image } from "rebass"

const authorLink = "https://twitter.com/rafalwilinski"
const authorName = "Rafal Wilinski"
const authorImage =
  "https://pbs.twimg.com/profile_images/1390706809548197895/SVCKpLNE_400x400.png"

export const Card = ({ model, icon, metadata, content }) => (
  <Flex
    key={"1"}
    width={[1, 0.49, 0.32]}
    className="model-card"
    mb={10}
    mt={10}
  >
    <a
      href={`/dynamodb-single-table-design-examples/${model.ModelName}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Flex
        flexDirection="column"
        style={{ padding: "2.5rem 1.5rem 0rem 1.5rem" }}
      >
        {icon && (
          <Image
            src={icon.download_url}
            style={{ width: "80px", marginBottom: "25px", marginTop: "5px" }}
          />
        )}
        <div>
          <h4
            style={{
              lineHeight: "1.2",
              marginTop: "10px",
              marginBottom: "5px",
              fontSize: "1.2em",
              color: "black",
            }}
          >
            {model.ModelName}
          </h4>
          <p
            style={{ color: "#333", fontSize: "0.85em", marginBottom: "10px" }}
          >
            {model.ModelMetadata.Description}
          </p>

          <hr />
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              marginTop: "-4px",
            }}
          >
            <div
              style={{
                color: "rgb(24, 144, 255)",
                marginRight: "16px",
                color: "#333",
                fontSize: "0.75em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AccessPattern />
              <div
                style={{
                  color: "#333",
                  fontWeight: 600,
                  margin: "0 4px",
                }}
              >
                {Object.keys(model.ModelSchema.models).length}
              </div>{" "}
              <div
                style={{
                  color: "#888",
                }}
              >
                Access Patterns
              </div>
            </div>

            <div
              style={{
                color: "#333",
                fontSize: "0.75em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Cube />
              <div
                style={{
                  color: "#333",
                  fontWeight: 600,
                  margin: "0 4px",
                }}
              >
                {Object.keys(model.ModelSchema.models).length}
              </div>{" "}
              <div
                style={{
                  color: "#888",
                }}
              >
                Entities
              </div>
            </div>
          </Flex>
          <hr />
          <div
            style={{ display: "flex", flexDirection: "row", color: "black" }}
          >
            <a
              href="https://twitter.com/rafalwilinski"
              rel="nofollow noopener noreferrer"
            >
              <img
                src={authorImage || metadata.authorImage}
                alt={authorName || metadata.authorName}
                style={{
                  width: "64px",
                  height: "64px",
                  borderRadius: "32px",
                  marginRight: "16px",
                }}
              />
            </a>
            <div>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "0.85rem",
                  textAlign: "left",
                  marginBottom: -4,
                  marginTop: "8px",
                }}
              >
                <a
                  href={authorLink || "https://twitter.com/rafalwilinski"}
                  style={{ color: "inherit", textDecoration: "none" }}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {model.ModelMetadata.Author}
                </a>
              </p>
              <p
                style={{
                  color: "#555",
                  fontWeight: 400,
                  fontSize: "0.85rem",
                  textAlign: "left",
                  marginBottom: "0.5rem",
                }}
              >
                added {renderPublishDifference(model.ModelMetadata.DateCreated)}{" "}
              </p>
            </div>
          </div>
        </div>
      </Flex>
    </a>
  </Flex>
)

const Cube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 26 26"
    stroke="#888"
    height={24}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
    />
  </svg>
)

const AccessPattern = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#888"
    height={20}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
)

const renderPublishDifference = date => {
  const dateCreated = new Date(date)
  const now = new Date()
  const diff = Math.abs(now.getTime() - dateCreated.getTime())
  const diffMonths = Math.ceil(diff / (1000 * 3600 * 24 * 30))
  if (diffMonths <= 1) {
    return "this month"
  }
  return `${diffMonths} months ago`
}
